<template>
	<div id="app">
		<div class="layout--main" :class="[navbarClasses, { 'app-page': isAppPage }]">
			<div id="content-overlay" />

			<vs-popup class="holamundo" title="Oops !" :active.sync="showError">
				<p>
					{{
						$t(
							"Une erreur est survenue, essayez de rafraichir la page. Si le problème persiste merci de nous en faire part."
						)
					}}
				</p>
				<vs-collapse>
					<vs-collapse-item>
						<div slot="header">Plus d'informations</div>
						<p class="whitespace-pre-line">
							Code d'erreur :
							{{ error === null ? "" : error.error.request.status }} Message :
							{{ error === null ? "" : error.error.message }} URL de l'appel :
							{{ error === null ? "" : error.error.request.responseURL }}
						</p>
					</vs-collapse-item>
				</vs-collapse>
				<vs-button @click="reload">{{ $t("Rafraichir") }}</vs-button>
			</vs-popup>

			<div class="content-wrapper">
				<the-navbar />

				<!-- switch accountants -->
				<vs-row vs-justify="center">
					<vs-row
						class="soft flex justify-center"
						:style="{
							visibility: selector_display ? 'visible' : 'hidden',
							width: '790px',
							'margin-top': '1.5rem',
							'margin-bottom': '1rem'
						}"
					>
						<vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="220px">
							<vs-tooltip :text="$t('tooltip entreprises')">
								<!-- class="hover:bg-primary rounded-full hover:text-white" -->
								<h3
									class="cursor-pointer hover:underline py-3 px-6 rounded-full"
									:class="{
										active: $route.name === 'Apps',
										'text-grey': $route.name !== 'Apps'
									}"
									@click="toggleApps('userApps')"
								>
									{{ $t("Mes entreprises") }}
								</h3>
							</vs-tooltip>
						</vs-col>
						<vs-col
							v-if="loadedCases && partner && partner.app_id"
							vs-type="flex"
							vs-justify="center"
							vs-align="center"
							vs-w="150px"
							class="vertical-separator"
						>
							<vs-tooltip :text="$t('tooltip partenaires')">
								<!-- class="hover:bg-primary rounded-full hover:text-white" -->
								<h3
									class="cursor-pointer hover:underline py-3 px-6 rounded-full"
									:class="{
										active: $route.name === 'Cases',
										'text-grey': $route.name !== 'Cases'
									}"
									@click="$router.push('/cases')"
								>
									{{ $t("Partenaires") }}
								</h3>
							</vs-tooltip>
						</vs-col>
						<vs-col
							v-if="display_swictch_accountant && partner && partner.collaborators_domain"
							vs-type="flex"
							vs-justify="center"
							vs-align="center"
							vs-w="210px"
							class="vertical-separator"
						>
							<vs-tooltip :text="$t('tooltip collaborateurs')">
								<!-- class="hover:bg-primary rounded-full hover:text-white" -->
								<h3
									class="cursor-pointer hover:underline py-3 px-6"
									:class="{
										active: $route.name === 'Accountants',
										'text-grey': $route.name !== 'Accountants'
									}"
									@click="$router.push('/accountants')"
								>
									{{ $t("Collaborateurs") }}
								</h3>
							</vs-tooltip>
						</vs-col>
					</vs-row>
				</vs-row>

				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
	import themeConfig from "@/../themeConfig.js";
	import TheNavbar from "./layouts/components/TheNavbar";
	import { mapGetters, mapState } from "vuex";
	import { includes } from "vuesax";

	export default {
		components: {
			TheNavbar
		},
		data() {
			return {
				navbarType: themeConfig.navbarType || "sticky",
				navbarColor: themeConfig.navbarColor || "#fff",
				routerTransition: themeConfig.routerTransition || "none",
				isNavbarDark: false,
				routeTitle: this.$route.meta.pageTitle,
				disableCustomizer: themeConfig.disableCustomizer,
				error: null,
				showError: false
			};
		},
		computed: {
			...mapState(["user", "loadedCases", "accountingFirm", "partner"]),
			...mapGetters(["showPartnerAppsSelector"]),
			isAppPage() {
				if (this.$route.path.includes("/apps/")) {
					return true;
				} else {
					return false;
				}
			},
			navbarClasses() {
				return {
					"navbar-hidden": this.navbarType == "hidden",
					"navbar-sticky": this.navbarType == "sticky",
					"navbar-static": this.navbarType == "static",
					"navbar-floating": this.navbarType == "floating"
				};
			},
			display_swictch_accountant() {
				return (
					this.accountingFirm &&
					(this.$route.name === "Accountants" || this.$route.name === "Apps" || this.$route.name === "Cases")
				);
			},
			selector_width() {
				return 220 + (this.display_swictch_accountant ? 210 : 0) + (this.loadedCases ? 150 : 0) + "px";
			},
			selector_display() {
				if (this.user === null) return false;

				return this.display_swictch_accountant || this.showPartnerAppsSelector;
			}
		},
		mounted() {
			document.addEventListener("apiError", error => {
				console.log(error);

				this.error = error;
				this.showError = true;
			});
		},
		methods: {
			reload() {
				location.reload();
			},
			toggleApps(value) {
				if (this.$route.name !== "Apps") {
					this.$router.push("/");
				}
			}
		}
	};
</script>

<style>
	#app {
		background-color: var(--background);
	}
	.content-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
	.layout--full-page {
		flex-grow: 5;
		position: relative;
	}
	.layout--middle {
		position: absolute;
		top: 45%;
		transform: translateY(-50%);
	}
	.vs-component.vs-button:disabled {
		opacity: 1;
		filter: brightness(175%) grayscale(50%);
	}
	.vs-component.vs-button:hover {
		background-color: #137ec8;
	}
	.text-grey {
		color: var(--grey);
	}
	.active {
		text-decoration: underline !important;
		color: var(--dark);
	}
	.vertical-separator {
		border-left: 1px solid var(--grey);
		height: 100%;
	}
	.soft {
		transition: all 400ms;
	}
</style>
