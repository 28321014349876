import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
import { getToken } from "sinao-corejs";
import i18n from "./i18n";

Vue.use(Router);

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "",
			component: () => import("@/layouts/main/Main.vue"),
			beforeEnter: (to, from, next) => {
				if (!["fr", "en", "es", "it"].includes(to.query.lang)) {
					to.query.lang = "fr";
				}

				const lang = to.query.lang || "fr";
				i18n.locale = lang;
				next();
			},
			children: [
				{
					path: "/",
					alias: "/index.html",
					name: "Apps",
					meta: {
						title: "Mes applications"
					},
					component: () => import("@/views/Apps.vue")
				},
				{
					path: ":appId/invite",
					name: "Invite",
					meta: {
						title: "Ajouter un utilisateur"
					},
					component: () => import("@/views/Invite.vue")
				},
				{
					path: ":appId/members",
					name: "Members",
					meta: {
						title: "Gérer les utilisateurs"
					},
					component: () => import("@/views/Members.vue")
				},
				{
					path: "/accountants",
					name: "Accountants",
					meta: {
						title: "Gérer les collaborateurs"
					},
					component: () => import("@/views/Accountants.vue")
				},
				{
					path: "/accountants/add",
					name: "Invite-accountant",
					meta: {
						title: "Ajouter un collaborateur"
					},
					component: () => import("@/views/InviteAccountant.vue")
				},
				{
					path: "/invitation/:tokenURL",
					name: "Invitation",
					meta: {
						title: "Rejoindre une application"
					},
					component: () => import("@/views/Invitation.vue")
				},
				{
					path: "/cases",
					name: "Cases",
					meta: {
						title: "Mes dossiers"
					},
					component: () => import("@/views/Cases.vue")
				}
			]
		},
		{
			path: "",
			component: () => import("@/layouts/full-page/FullPage.vue"),
			children: [
				{
					path: "/register",
					name: "Register",
					meta: {
						title: "Inscription"
					},
					component: () => import("@/views/Register.vue")
				},
				{
					path: "/login",
					name: "Login",
					meta: {
						title: "Connexion"
					},
					component: () => import("@/views/Login.vue")
				},
				{
					path: "/error-404",
					name: "Error404",
					meta: {
						title: "Page introuvable"
					},
					component: () => import("@/views/Error404.vue")
				},
				{
					path: "/changepassword/:tokenURL",
					name: "Changepassword",
					meta: {
						title: "Changer mon mot de passe"
					},
					component: () => import("@/views/Changepassword.vue")
				},
				{
					path: "/sendpassword",
					name: "Sendpassword",
					meta: {
						title: "Réinitialiser mon mot de passe"
					},
					component: () => import("@/views/Sendpassword.vue")
				}
			]
		},
		{
			path: "*",
			component: () => import("@/views/Error404.vue")
		}
	]
});

router.beforeEach(async (to, from, next) => {
	try {
		let isQueryClean = true;

		Object.keys(from.query).forEach(queryKey => {
			isQueryClean = isQueryClean && typeof to.query[queryKey] !== `undefined`;
		});

		if (!isQueryClean) {
			return router.replace({
				...to,
				query: {
					...from.query,
					...to.query
				}
			});
		}

		const branding = await store.dispatch("branding");

		// Login
		const unloggedPages = ["Sendpassword", "Invitation", "Login", "Changepassword", "Register"];

		if (getToken()) {
			if (!store.state.user) {
				await store.dispatch("getUser");
			}

			if (unloggedPages.includes(to.name)) {
				next({ name: "Apps" });
			}
		} else {
			if (!unloggedPages.includes(to.name)) {
				next({ name: "Login" });
			}
		}
	} catch (error) {
		console.log(error);
	} finally {
		next();
	}
});

// fix tooltips sticking around after rout navigation
router.afterEach(() => {
	const Stickedtooltips = document.querySelectorAll(".vs-tooltip");

	for (const tooltip of Stickedtooltips) {
		tooltip.remove();
	}
});

// before each page navigation, update the title of the tab if necessary
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.metaTags);
	// const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = `${nearestWithTitle.meta.title} - ${store.state.name}`;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(el => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) {
		return next();
	}

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map(tagDef => {
			const tag = document.createElement("meta");

			Object.keys(tagDef).forEach(key => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create, so we don't interfere with other ones.
			tag.setAttribute("data-vue-router-controlled", "");

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach(tag => document.head.appendChild(tag));

	next();
});

export default router;
