const mutations = {
	SET_USER(state, payload) {
		state.user = payload;
	},
	SET_LOGO(state, payload) {
		state.logo = payload;
	},
	SET_NAME(state, payload) {
		state.name = payload;
	},
	SET_LOADED_APPS(state, payload) {
		state.loadedApps = payload;
	},
	SET_LOADED_POLICIES(state, payload) {
		state.loadedPolicies = payload;
	},
	SET_LOADED_CASES(state, payload) {
		state.loadedCases = payload;
	},
	SET_PARTNER(state, payload) {
		state.partner = payload;
	},
	SET_LICENSE_PLANS(state, payload) {
		state.licensePlans = payload;
	},
	SET_ACCOUNTING_FIRM(state, payload) {
		state.accountingFirm = payload;
	},
	UPDATE_SUBSCRIPTION(state, payload) {
		delete payload.subscription.app;
		delete payload.subscription.subscriptions;
		state.loadedCases.find(app => app.id === payload.app_id).subscription = payload.subscription;
	},
	UPDATE_TOTAL_LICENSE(state, payload) {
		state.partner.total_license = payload.total_license;
	}
};

export default mutations;
