var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"layout--main",class:[_vm.navbarClasses, { 'app-page': _vm.isAppPage }]},[_c('div',{attrs:{"id":"content-overlay"}}),_c('vs-popup',{staticClass:"holamundo",attrs:{"title":"Oops !","active":_vm.showError},on:{"update:active":function($event){_vm.showError=$event}}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "Une erreur est survenue, essayez de rafraichir la page. Si le problème persiste merci de nous en faire part." ))+" ")]),_c('vs-collapse',[_c('vs-collapse-item',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Plus d'informations")]),_c('p',{staticClass:"whitespace-pre-line"},[_vm._v(" Code d'erreur : "+_vm._s(_vm.error === null ? "" : _vm.error.error.request.status)+" Message : "+_vm._s(_vm.error === null ? "" : _vm.error.error.message)+" URL de l'appel : "+_vm._s(_vm.error === null ? "" : _vm.error.error.request.responseURL)+" ")])])],1),_c('vs-button',{on:{"click":_vm.reload}},[_vm._v(_vm._s(_vm.$t("Rafraichir")))])],1),_c('div',{staticClass:"content-wrapper"},[_c('the-navbar'),_c('vs-row',{attrs:{"vs-justify":"center"}},[_c('vs-row',{staticClass:"soft flex justify-center",style:({
						visibility: _vm.selector_display ? 'visible' : 'hidden',
						width: '790px',
						'margin-top': '1.5rem',
						'margin-bottom': '1rem'
					})},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"right","vs-align":"center","vs-w":"220px"}},[_c('vs-tooltip',{attrs:{"text":_vm.$t('tooltip entreprises')}},[_c('h3',{staticClass:"cursor-pointer hover:underline py-3 px-6 rounded-full",class:{
									active: _vm.$route.name === 'Apps',
									'text-grey': _vm.$route.name !== 'Apps'
								},on:{"click":function($event){return _vm.toggleApps('userApps')}}},[_vm._v(" "+_vm._s(_vm.$t("Mes entreprises"))+" ")])])],1),(_vm.loadedCases && _vm.partner && _vm.partner.app_id)?_c('vs-col',{staticClass:"vertical-separator",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"150px"}},[_c('vs-tooltip',{attrs:{"text":_vm.$t('tooltip partenaires')}},[_c('h3',{staticClass:"cursor-pointer hover:underline py-3 px-6 rounded-full",class:{
									active: _vm.$route.name === 'Cases',
									'text-grey': _vm.$route.name !== 'Cases'
								},on:{"click":function($event){return _vm.$router.push('/cases')}}},[_vm._v(" "+_vm._s(_vm.$t("Partenaires"))+" ")])])],1):_vm._e(),(_vm.display_swictch_accountant && _vm.partner && _vm.partner.collaborators_domain)?_c('vs-col',{staticClass:"vertical-separator",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"210px"}},[_c('vs-tooltip',{attrs:{"text":_vm.$t('tooltip collaborateurs')}},[_c('h3',{staticClass:"cursor-pointer hover:underline py-3 px-6",class:{
									active: _vm.$route.name === 'Accountants',
									'text-grey': _vm.$route.name !== 'Accountants'
								},on:{"click":function($event){return _vm.$router.push('/accountants')}}},[_vm._v(" "+_vm._s(_vm.$t("Collaborateurs"))+" ")])])],1):_vm._e()],1)],1),_c('router-view')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }