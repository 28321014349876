/* eslint-disable camelcase */
const state = {
	user: null,
	name: null,
	logo: null,
	loadedApps: null,
	loadedPolicies: null,
	loadedCases: null,
	licensePlans: null,
	accountingFirm: null,
	partner: null
};

export default state;
