export default {
	getImageUrl: () => item => {
		if (item?.image) {
			const { image } = item;

			if (image.includes("http")) {
				return image;
			} else if (image.includes("/services/")) {
				return `https://sinao.app${image}`;
			} else {
				return `https://sinao-prod-storage.s3.eu-west-3.amazonaws.com/${image}`;
			}
		}
	},
	sorted_cases(state) {
		const cases = state.loadedCases ?? [];
		return [...cases].sort((a, b) =>
			a.organization.name.localeCompare(b.organization.name, "fr", { sensitivity: "base" })
		);
	},
	accountant_policy_id(state) {
		return state.accountingFirm.policy_id;
	},
	loaded_apps(state) {
		return state.loadedApps;
	},
	loaded_policies(state) {
		return state.loadedPolicies;
	},
	loaded_cases(state) {
		return state.loadedCases;
	},
	license_plans(state) {
		return state.licensePlans;
	},
	showPartnerAppsSelector(state) {
		return state.loadedCases && state.loadedCases.length > 0;
	}
};
